import React, { useState, useEffect } from 'react';
import trix from 'trix';
import PortalConfig from '../../../config';
import Cookies from 'js-cookie';
import "trix/dist/trix.css";
import Axios from 'axios';
import notie from 'notie'
import classNames from '../../../Helper';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon, PaperClipIcon } from '@heroicons/react/outline';

function ShowCaseUpdates(props)
{
    const [caseUpdates, setCaseUpdates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [update, setUpdate] = useState('');
    const trixInput = React.createRef();
    const fileInput = React.createRef();
    const [file_error, setFileError] = useState('');

    useEffect(() => {
        setCaseUpdates(props.caseUpdates);
        trixInput.current.addEventListener("trix-change", event => {
            handleChange(event);
        });
    }, [props]);

    function handleChange(event)
    {
        setUpdate(event.target.value);
    }

    function handleFileChange(event)
    {
        var size = event.target.files[0].size;
        var limit = parseInt(Cookies.get('max_attach_file_size'));
        setFileError('');
        if(limit < size ){
            var fileMaxSizeBytes = parseInt(Cookies.get('max_attach_file_size'));
            var fileMaxSizeMegaBytes = fileMaxSizeBytes / 1000000;
            let sizeError = 'Votre fichier est trop volumineux. La taille maximale autorisée est '+fileMaxSizeMegaBytes+' Mo'; 
            notie.alert({ type: 'warning', text: sizeError, time: 3 }); 
            // setFileError(sizeError);
        } 
    }

    function addComment()
    {
        let method = 'post';
        let endpoint_url = PortalConfig.crm_url + '/add-comment/' + props.recordId;

        if(!update) {
            notie.alert({ type: 'warning', text: 'Veuillez ajouter un message ou un commentaire', time: 3 });
            return false;
        }

        let post_data = new FormData();
        post_data.append('comment', update);
        if(fileInput.current && fileInput.current.files[0]) {
            var size = fileInput.current.files[0].size;
            var limit = parseInt(Cookies.get('max_attach_file_size'));
            if(limit > size ){
                post_data.set('filename', fileInput.current.files[0]);
                setFileError('');
            }else{
                var fileMaxSizeBytes = parseInt(Cookies.get('max_attach_file_size'));
                var fileMaxSizeMegaBytes = fileMaxSizeBytes / 1000000;
                let sizeError = 'Votre fichier est trop volumineux. La taille maximale autorisée est '+fileMaxSizeMegaBytes+' Mo';
                notie.alert({ type: 'warning', text: sizeError, time: 3 }); 
              //  setFileError(sizeError);            
                return false;
            }
        }   
        const token = Cookies.get('ba_customer_portal_token');

        const config = {
            url: endpoint_url,
            method: method,
            data: post_data,
            headers: {
                Authorization: 'bearer ' + token,
            }
        };

        setLoading(true);
        notie.alert({ type: 'info', text: 'Chargement de votre fichier', time:10  });   
        Axios(config).then((response) => {
            setLoading(false);
            notie.alert({ type: '', text: '', time:1  });   
            if(response.data.status === true) {
                notie.alert({ type: 'success', text: 'commentaire ajouté avec succès', time:2 });
                setCaseUpdates(response.data.case_updates);
                // Reset the form values
                setUpdate('');
                var element = document.querySelector("trix-editor");
                element.value = '';
                document.getElementById('case_attachment').value = '';
            }
            else {
                // Show error message
            }
        })
        .catch((error) => {
            setLoading(false);
            console.log(error);
        });
    }

    function downloadTheDocument(note_id, file_name)
    {
        const token = Cookies.get('ba_customer_portal_token');
        const config = {
            responseType: 'blob',
            headers: {
                Authorization: 'bearer ' + token,
            }
        };
        
        Axios.get(PortalConfig.crm_url + '/Notes/download/' + note_id, config).then((response) => {
            if(response.data.message) {

                return false;
            }
            // Get the data from server and download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const downloadLink = document.createElement('a');
            downloadLink.href = url;
            downloadLink.setAttribute('download', file_name);
            document.body.appendChild(downloadLink);
            downloadLink.click();
        }).catch((error) => {
            if (error.message == 'Request failed with status code 401') {
                this.props.updateLoginStatus(false);
            }
        });
    }

    function createMarkup(html) {
        return { __html: html };
    }

    return (
        <>
            <div className="py-3 pt-7">
                <h3 className="text-lg leading-6 font-medium text-gray-900">{props.labels.case_updates ? props.labels.case_updates : 'Case Updates'}</h3>
            </div>
            <hr />
            <div className="">
                <div className="mx-auto pb-4">
                    <div className="mx-auto divide-y-2 divide-gray-200">
                        <dl className="space-y-3 divide-y divide-gray-200">
                            {caseUpdates.map((data, index) => (
                                <Disclosure as="div" key={index} className="bg-white rounded-lg p-4">
                                    {({ open }) => (
                                        <>
                                            <dt className="text-sm">
                                                <Disclosure.Button className="text-left w-full flex justify-between items-start text-gray-400">
                                                    <span className="text-gray-700 font-semibold">
                                                        <span className="flex">
                                                            <div>{data.created_by}</div>
                                                            {data.attachment && data.attachment.id ?
                                                                <div><PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400 ml-2" aria-hidden="true" /> </div>
                                                            : ''}
                                                        </span>
                                                    </span>
                                                    <span className="ml-6 h-7 flex items-center">
                                                        <span className="text-gray-900 pr-5">{data.date_entered}</span>
                                                        <ChevronDownIcon
                                                            className={classNames(open ? '-rotate-180' : 'rotate-0', 'h-6 w-6 transform')}
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                </Disclosure.Button>
                                            </dt>
                                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                                                <p className="text-sm text-gray-500">
                                                    <span dangerouslySetInnerHTML={createMarkup(data.description)}></span>
                                                    {data.attachment && data.attachment.id ? 
                                                        <li className="mt-2 pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-white rounded-lg">
                                                            <div className="w-0 flex-1 flex items-center">
                                                                <PaperClipIcon className="flex-shrink-0 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                                <span className="ml-2 flex-1 w-0 truncate">{data.attachment.name}</span>
                                                            </div>
                                                            <div className="ml-4 flex-shrink-0">
                                                                <button type='button' className="font-medium text-skin-primary-dark hover:text-skin-primary" onClick={() => downloadTheDocument(data.attachment.id, data.attachment.name)}>
                                                                    Download
                                                                </button> 
                                                            </div>
                                                        </li>
                                                    : ''}
                                                </p>
                                            </Disclosure.Panel>
                                        </>
                                    )}
                                </Disclosure>
                            ))}
                        </dl>
                    </div>
                </div>
            </div>
            <div className="pt-4 pb-2">
                <input id='case_update' type="hidden" name='case_update' value={update} onChange={handleChange} /> <trix-editor placeholder="Attention: les demandes transmises dans le portail ne doivent pas contenir de données de santé ou a caractère personnel." input='case_update' ref={trixInput}></trix-editor>
            </div>
            <div className="form-group">
                <input type='file' id='case_attachment' name='case_attachment' className="" onChange={handleFileChange} ref={fileInput} />
                <span className='text-sm text-red-500'>{file_error} </span>
            </div>
            <div className="form-group pt-4">
                <button type='button' className='order-0 inline-flex items-center px-4 py-1 border-2 hover:border-transparent border-skin-primary shadow-sm text-sm font-medium rounded-md text-skin-base hover:text-white hover:bg-skin-primary-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-primary sm:order-1' onClick={addComment} disabled={loading == true ? 'disabled' : ''}>{props.labels.add ? props.labels.add : 'Add comment'}</button>
                <div className={loading === true ? "spinner-border text-primary spinner-border-sm ml-1" : "spinner-border text-primary spinner-border-sm ml-1 d-none"} role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        </>
    );
}

export default ShowCaseUpdates;