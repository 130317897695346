import React, { Component } from 'react';
import { Link } from "react-router-dom";

class Sidebar extends Component
{
    render() {
        return (
            <div className="row">
                <nav  className="col-md-2 d-none d-md-block bg-light sidebar">
                    <div className="sidebar-sticky"> 
                        <ul className="nav flex-column">
                            <li className="nav-item" key="dashboard">
                                <Link to={`${process.env.PUBLIC_URL}/`} className="nav-link">
                                    <span data-feather="home"></span>
                                    <span> Dashboard <span className="sr-only">(current)</span> </span>
                                </Link>
                            </li>
                            {this.props.modules.map((module) => {
                                let module_icon = 'file-text';
                                if(module == 'Documents') {
                                    module_icon = 'file-text';
                                }
                                else if(module == 'Cases') {
                                    module_icon = 'life-buoy';
                                }
                                else if(module == 'knowledge_base') {
                                    module_icon = 'book-open';
                                }
                                else if(module == 'Meetings') {
                                    module_icon = 'users';
                                }

                                return (
                                    <li className="nav-item" key={module.toLowerCase()}>
                                        <Link to={`${process.env.PUBLIC_URL}/${module}`} className="nav-link">
                                            <span data-feather={module_icon}></span>
                                            <span> {this.props.moduleLabels[module]} </span>
                                        </Link>
                                    </li>
                                );
                            })}
                        </ul>
                    </div>
                </nav>
            </div>
        );
    }
}

export default Sidebar;
