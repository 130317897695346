import React, { Component } from 'react';
import Axios from 'axios';
import PortalConfig from '../../../config';
import Feather from 'feather-icons';
import Cookies from 'js-cookie';
import { Link } from "react-router-dom";
import ListView from './ListView';
import ShowCaseUpdates from "./ShowCaseUpdates";
import LineItems from "./LineItems";
import { ChevronRightIcon } from '@heroicons/react/outline';
import Alert from '../../Alerts/Alert';

class DetailView extends Component
{
    state = {
        name: '',
        record_info: [],
        message: '',
        loading: false,
        layout_column: 1,
        file_name: '',
        actions: [],
        show_case_updates: false,
        case_updates: [],
        labels: [],
        line_items: [],
    }                                                                                                                                                                                                                                               

    constructor(props) {
        super(props);
        this.downloadTheDocument = this.downloadTheDocument.bind(this);
        this.hideErrorMessage = this.hideErrorMessage.bind(this);
        this.createMarkup = this.createMarkup.bind(this);
        this.formatBytes = this.formatBytes.bind(this);
        this.downloadThisFile = this.downloadThisFile.bind(this);
    }

    // Set loading and message to null
    hideErrorMessage(event) {
        this.setState({
            loading: false,
            message: ''
        });    
    }

    componentDidMount() {
        this.getRecordInfo();
    }

    componentDidUpdate(prevProps, prevState) {
        if(prevProps.selected_module != this.props.selected_module) {
            this.getRecordInfo();
        }
    }

    createMarkup(html) {
        return { __html: html };
    }

    /**
     * Download the document
     **/ 
    downloadTheDocument(field_name, file_name) {
        const token = Cookies.get('ba_customer_portal_token');
        const config = {
            responseType: 'blob',
            headers: {
                Authorization: 'bearer ' + token,
            }
        };
        
        Axios.get(PortalConfig.crm_url + '/' + this.props.selected_module + '/download/' + this.props.record_id + '?field_name=' + field_name + '&file_name=' + file_name, config).then((response) => {
            let d_file_name = field_name ? file_name : this.state.file_name;
            // Get the data from server and download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const downloadLink = document.createElement('a');
            downloadLink.href = url;
            downloadLink.setAttribute('download', d_file_name);
            document.body.appendChild(downloadLink);
            downloadLink.click();
        }).catch((error) => {
            this.setState({
                loading: false,
                message: error.message,
            });

            if (error.message == 'Request failed with status code 401') {
                this.props.updateLoginStatus(false);
            }
        });
    }

    downloadThisFile(field_name, file, is_zip = false) {
        const token = Cookies.get('ba_customer_portal_token');
        const config = {
            responseType: 'blob',
            headers: {
                Authorization: 'bearer ' + token,
            }
        };
        let url = '';
        let file_name = field_name;

        if(!is_zip) {
            file_name = file.file_name;
            url = PortalConfig.crm_url + '/' + this.props.selected_module + '/download/' + this.props.record_id + '?field_name=' + field_name + '&file_name=' + file_name + '&file_id=' + file.id + '&is_zip=false';
        } else {
            url = PortalConfig.crm_url + '/' + this.props.selected_module + '/download/' + this.props.record_id + '?field_name=' + field_name + '&is_zip=true';
        }

        Axios.get(url, config).then((response) => {
            let d_file_name = field_name ? file_name : this.state.file_name;
            // Get the data from server and download
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const downloadLink = document.createElement('a');
            downloadLink.href = url;
            downloadLink.setAttribute('download', d_file_name);
            document.body.appendChild(downloadLink);
            downloadLink.click();
        }).catch((error) => {
            this.setState({
                loading: false,
                message: error.message,
            });

            if (error.message == 'Request failed with status code 401') {
                this.props.updateLoginStatus(false);
            }
        });
    }

    getRecordInfo() {
        // Show loading icon
        this.setState({
            loading: true,
        });

        const token = Cookies.get('ba_customer_portal_token');
        const config = {
            headers: {
                Authorization: 'bearer ' + token,
            }
        };

        Axios.get(PortalConfig.crm_url + '/' + this.props.selected_module + '/' + this.props.record_id + '/detailview', config).then((response) => {
            if(response.data.status === false) {
                this.setState({
                    message: response.data.message,
                    loading: false,
                });
            }
            else {
                // Preparing new state
                let newState = {
                    name: response.data.name,
                    record_info: response.data.record_info,
                    layout_column: response.data.layout_column,
                    loading: false,
                    actions: response.data.actions,
                    show_case_updates: response.data.show_case_updates,
                    case_updates: response.data.case_updates,
                    show_project_tasks: response.data.show_project_tasks,
                    labels: response.data.labels,
                    line_items: response.data.line_items,
                    field_perimission: response.data.field_permissions,
                }

                if(this.props.selected_module == 'Documents') {
                    newState['file_name'] = response.data.fields.filename;
                }
                
                this.setState(newState);
                 
                Feather.replace();
            }
        }).catch((error) => {
            this.setState({
                loading: false,
                message: error.message,
            });

            if (error.message == 'Request failed with status code 401') {
                this.props.updateLoginStatus(false);
            }
        });
    }

    formatBytes(bytes, decimals = 2) {
        if (!+bytes) return '0 Bytes'
    
        const k = 1024
        const dm = decimals < 0 ? 0 : decimals
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    
        const i = Math.floor(Math.log(bytes) / Math.log(k))
    
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    }

    render() {

        let previewImageStyle = {
            border: '1px solid #ddd',
            borderRadius : '4px',
            padding: '5px',
            width: '150px',
            marginTop:'1rem'
        };

        return (
            <div>
                <nav className="flex justify-between" aria-label="Breadcrumb">
                    <ol className="flex items-center space-x-2 bg-white rounded-lg p-4">
                        <li>
                            <div>
                                <Link style={{color: "#1F3B71"}} className="text-skin-primary hover:text-skin-primary-darker" to={`${process.env.PUBLIC_URL}/${this.props.selected_module}`}>{this.props.moduleLabel}</Link>
                            </div>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <ChevronRightIcon className="flex-shrink-0 h-4 w-4 text-gray-400" aria-hidden="true" />
                                <p className='pl-2'>{this.state.name}</p>
                            </div>  
                        </li>
                    </ol>
                    <ol className="flex space-x-2">
                        <li> 
                            {this.state.actions && this.state.actions.includes('edit') ?
                                <Link to={`${process.env.PUBLIC_URL}/${this.props.selected_module}/edit/${this.props.record_id}`} className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-skin-muted bg-skin-primary-dark hover:bg-skin-primary-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-primary sm:order-1 sm:ml-3">
                                    {this.props.module_action_label && this.props.module_action_label.edit ? this.props.module_action_label.edit : 'Editer'}
                                </Link> 
                            : ''}
                        </li>   
                    </ol>   
                </nav>

                {this.state.message ? <Alert message={this.state.message} type='danger' hideAlert={this.hideErrorMessage} /> : ''}
                
                {this.state.record_info.map((layout_info, layout_index) => {
                    let field_chunks = [];
                    if(layout_info.fields) {
                       while (layout_info.fields.length) {
                            field_chunks.push(layout_info.fields.splice(0, this.state.layout_column));
                        }
                    }
                    
                    let edit_view_url = '';
                    edit_view_url = `${process.env.PUBLIC_URL}/${this.props.selected_module}/edit/${this.props.record_id}`;

                    let column_class = 'md:grid-cols-1';
                    if(this.state.layout_column == 2) {
                        column_class = 'md:grid-cols-2';
                    }            
                    else if(this.state.layout_column == 3) {
                        column_class = 'md:grid-cols-3';
                    }

                    return (
                        <div key={layout_index}>
                             <div className="pt-7 pb-1">
                                <h3 className="text-xl leading-6 font-medium text-gray-900">{layout_info.header_name}</h3>
                                {/* {layout_index == 0 ? 
                                    <p> 
                                        {this.props.selected_module == 'Documents' ? <button onClick={() => this.downloadTheDocument('', '')} className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-skin-muted bg-skin-primary-dark hover:bg-skin-primary-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-primary sm:order-1 sm:ml-3"><span data-feather='download'> </span> Download</button> : ""}
                                    </p> : ""
                                } */}
                            </div> 
                            <div className="border border-gray-200 py-3 bg-white p-4 rounded-lg" style={{backgroundColor : '#f5f5fa'}}>
                                <div className={`grid ${column_class} gap-x-4 gap-y-4 sm:grid-cols-2`}> 
                            {field_chunks.map((fields, index) => {
                                return (
                                    <>
                                        {fields.map((field_info, index) => {
                                            if(field_info.type == 'file' && this.props.selected_module != 'Documents') {
                                                return (
                                                    <div key={index}>
                                                        <div className="row">
                                                            <dt className="text-sm font-medium text-gray-500">{field_info['label']}</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                <a target='_blank' href='#' onClick={() => this.downloadTheDocument(field_info.field_name, field_info['value'])}>{field_info['value'] != 'null' ? field_info['value'] : ''}</a>
                                                            </dd>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                            if(field_info.type == 'drag_n_drop' && this.props.selected_module != 'Documents') {
                                                return(
                                                    <div key={index}>
                                                        <div className="row">
                                                            <dt className="text-sm font-medium text-gray-500 flex">{field_info['label']}
                                                                {/* <span className='font-semibold text-red-500 ml-5'>
                                                                   <div className='cursor-pointer' onClick={() => this.downloadThisFile(field_info.field_name, field_info['label'], true)}>Download</div>
                                                                </span> */}
                                                            </dt>
                                                            <dd className="mt-1 text-sm text-gray-900  w-1/2 m-1">
                                                                {field_info['value'] ? 
                                                                    (field_info['value'].map((file, index) => (
                                                                        <div className="p-3 flex justify-between m-1 bg-white">
                                                                          <span className='flex'>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                                                            </svg>
                                                                            <span className='ml-2'>{file.file_name} - {this.formatBytes(file.file_size)} </span>
                                                                          </span>  
                                                                          <span className="cursor-pointer px-3" onClick={() => this.downloadThisFile(field_info.field_name, file, false)}> 
                                                                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 hover:text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                                              <path stroke-linecap="round" stroke-linejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
                                                                            </svg>
                                                                          </span>
                                                                        </div>
                                                                    )))
                                                                : ""}
                                                            </dd>
                                                        </div>
                                                    </div>
                                                );
                                            }

                                            if(field_info.type == 'checkbox') {
                                                return(
                                                    <div key={index}>
                                                        <div className="row">
                                                            <dt className="text-sm font-medium text-gray-500">{field_info['label']}</dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                {field_info['value'] == 'On' ? 'OUI' : 'NON'}
                                                            </dd>
                                                        </div>
                                                    </div>
                                                );
                                            }

                                            return (
                                                <>
                                                <div className="sm:col-span-1" key={index}>
                                                   {(this.state.field_perimission.hide == undefined && Object.keys(this.state.field_perimission.hide).length == 0 ) || (this.state.field_perimission.hide && (this.state.field_perimission.hide).indexOf(field_info['field_name']) < 0)  &&
                                                        <>
                                                            <dt className="text-sm font-medium text-gray-500">
                                                                
                                                                {this.state.field_perimission.read_only && (this.state.field_perimission.read_only).indexOf(field_info.field_name) > -1 ?
//                                                                     <b className='text-red-600 flex'> 
                                                                       
//                                                                         <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
//   <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
// </svg>
//                                                                             Avant d'aller plus loin, vous pourrez peut-être trouver la {field_info['label']} dans nos boîtes à outils:
//                                                                      </b>
                                                                    
<></>
                                                                :
                                                                    <>  {field_info['label']} </>
                                                                }
                                                                </dt>
                                                            <dd className="mt-1 text-sm text-gray-900">
                                                                {field_info['type'] === 'textarea' ?
                                                                    <>
                                                                    {field_info['value'] != 'null' ?
                                                                        <span className="" dangerouslySetInnerHTML={this.createMarkup(field_info['value'])}></span>
                                                                    : ''}
                                                                    </>
                                                                : 
                                                                    <>
                                                                        {(field_info['type'] == 'image' && field_info['value']) ? 
                                                                            <img src={`data:image/jpeg;base64,${field_info['value']}`} alt="Image preview" style={previewImageStyle} /> 
                                                                        : 
                                                                            <span> 
                                                                                {this.state.field_perimission.label && (this.state.field_perimission.label).indexOf(field_info['field_name']) > -1  ?
                                                                                  <> {field_info['value'] != 'null' ? 
                                                                                    <>
                                                                                        {field_info['value'].split(';').map((line) => (
                                                                                            <span className='text-red-600'>
                                                                                            {/* {line.includes('http') ?
                                                                                               <p> - <a href={line.replaceAll('"', '')} target="_blank"> {line.replaceAll('"', '')} </a> </p> 
                                                                                             : <p> - {line.replaceAll('"', '')} </p>
                                                                                            } */}
                                                                                            </span>
                                                                                        ))}
                                                                                    </>
                                                                                  : ''} </>
                                                                                :
                                                                                    <> {field_info['value'] != 'null' ? field_info['value'] : ''}  </>
                                                                                }        
                                                                            </span> 
                                                                        } 
                                                                    </>
                                                                }    
                                                            </dd>
                                                        </>
                                                    }
                                                </div>
                                                {field_info['field_name'] == 'filename' &&
                                                    <>
                                                        <span className="text-sm font-medium text-gray-500 float-right" > 
                                                            <span className="py-3">                                                                           
                                                                        {this.props.selected_module == 'Documents' ? <button onClick={() => this.downloadTheDocument('', '')} className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-skin-muted bg-skin-primary-dark hover:bg-skin-primary-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-primary sm:order-1 "><span data-feather='download'> </span> Télécharger le fichier</button> : ""}
                                                            </span>
                                                        </span>
                                                    </>
                                                }    
                                                </>
                                            );
                                        })}
                                    </>
                                )
                            })}
                                </div>
                            </div>
                        </div>
                    );
                })}

                {this.state.show_case_updates == 'true' ? 
                    <ShowCaseUpdates labels={this.state.labels} updateLoginStatus={this.props.updateLoginStatus} caseUpdates={this.state.case_updates} recordId={this.props.record_id} />
                    : ''
                }

                {this.state.line_items.length > 0 && this.props.selected_module == 'AOS_Invoices' ? 
                    <LineItems groups={this.state.line_items} />
                    : ''
                }

                {this.state.show_project_tasks == 'true' ? 
                    <ListView 
                        history={this.props.history} 
                        updateLoginStatus={this.props.updateLoginStatus} 
                        selected_module='ProjectTask' 
                        page='1'
                        parent_id={this.props.record_id} 
                        moduleLabel='Project Tasks'
                    />
                    : ''
                }
            </div>
        );
    }
}

export default DetailView;
