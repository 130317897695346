import React, { Component } from 'react';
import Axios from 'axios';
import PortalConfig from '../../../config';
import Cookies from 'js-cookie';
import { Link } from "react-router-dom";
import Alert from '../../Alerts/Alert';

// Echarts
import * as echarts from 'echarts/core';
import { TooltipComponent, LegendComponent, GridComponent } from 'echarts/components';
import { PieChart, LineChart, BarChart } from 'echarts/charts';
import { CanvasRenderer } from 'echarts/renderers';
import { faFileInvoice, faTasks } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

echarts.use(
    [TooltipComponent, LegendComponent, PieChart, LineChart, BarChart, CanvasRenderer, GridComponent]
);

const no_data_message = 'No record to display.';

class Dashboard extends Component
{
    state = {
        message: '',
        data: [],
        loading: false,
    };

    constructor(props) {
        super(props);
        this.redirectToModule = this.redirectToModule.bind(this);
        this.redirectToArticleLink = this.redirectToArticleLink.bind(this);
        this.createMarkup = this.createMarkup.bind(this);
    }

    componentDidMount() {
        // Get data needed for Dashboard module
        this.getDashboardData();        
    }

    // Redirect to module's list view
    redirectToModule(module) {
        this.props.history.push(process.env.PUBLIC_URL + '/' + module)
    }

    /**
     * Get dashboard
     */
    getDashboardData() {
        // Show loading icon
        this.setState({
            loading: true,
        });

        const token = Cookies.get('ba_customer_portal_token');
        const config = {
            headers: {
                Authorization: 'bearer ' + token,
            }
        };

        Axios.get(PortalConfig.crm_url + '/dashboard', config).then((response) => {
            this.setState({
                data: response.data,
                loading: false,
            });

            if(response.data.tasks && response.data.tasks.length > 0) {
                var taskChartDom = document.getElementById('tasks');
                var taskChart = echarts.init(taskChartDom);
                let taskLabels = [];
                let taskValues = [];
                response.data.tasks.map((value, index) => {
                    taskLabels.push(response.data.tasks[index]['status']);
                    taskValues.push(response.data.tasks[index]['total']);
                });

                let taskOption = {
                    xAxis: {
                        type: 'category',
                        data: taskLabels
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: [{
                        data: taskValues,
                        type: 'line'
                    }]
                };
                
                taskOption && taskChart.setOption(taskOption);
            }

            if(response.data.open_ticket_by_priority.data && response.data.open_ticket_by_priority.data.length > 0) {
                var priorityChartDom = document.getElementById('open_tickets_by_priority');
                var priorityChart = echarts.init(priorityChartDom);
                let priorityData = [];
                response.data.open_ticket_by_priority.data.map((value, index) => {
                    priorityData.push({
                        name: response.data.open_ticket_by_priority.labels[index],
                        value: response.data.open_ticket_by_priority.data[index],
                    })
                });

                var priorityOption = {
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        top: '5%',
                        left: 'center'
                    },
                    series: [
                        {
                            name: 'Open Tickets by Priority',
                            type: 'pie',
                            radius: ['40%', '70%'],
                            avoidLabelOverlap: false,
                            itemStyle: {
                                borderRadius: 10,
                                borderColor: '#fff',
                                borderWidth: 2
                            },
                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '40',
                                    fontWeight: 'bold'
                                }
                            },
                            labelLine: {
                                show: false
                            },
                            data: priorityData,
                        }
                    ]
                };
                
                priorityOption && priorityChart.setOption(priorityOption);
            }

            if(response.data.tickets_by_status.data && response.data.tickets_by_status.data.length > 0) {
                var statusChartDom = document.getElementById('tickets_by_status');
                var statusChart = echarts.init(statusChartDom);
                let statusData = [];
                response.data.tickets_by_status.data.map((value, index) => {
                    statusData.push({
                        name: response.data.tickets_by_status.labels[index],
                        value: response.data.tickets_by_status.data[index],
                    })
                });

                var statusOption = {
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        top: '5%',
                        left: 'center'
                    },
                    series: [
                        {
                            name: 'Tickets by status',
                            type: 'pie',
                            radius: ['40%', '70%'],
                            avoidLabelOverlap: false,
                            itemStyle: {
                                borderRadius: 10,
                                borderColor: '#fff',
                                borderWidth: 2
                            },
                            label: {
                                show: false,
                                position: 'center'
                            },
                            emphasis: {
                                label: {
                                    show: true,
                                    fontSize: '40',
                                    fontWeight: 'bold'
                                }
                            },
                            labelLine: {
                                show: false
                            },
                            data: statusData,
                        }
                    ]
                };
                
                statusOption && statusChart.setOption(statusOption);
            }

            if(response.data.tasks_all_vs_closed && response.data.tasks_all_vs_closed.length > 0) {
                var taskChartDom = document.getElementById('tasks_all_vs_closed');
                var taskChart = echarts.init(taskChartDom);

                let taskData = {
                    All: [],
                    Closed: [],
                };

                let taskLabels = []; 
                // Preparing labels and data
                response.data.tasks_all_vs_closed.map((value) => {
                    taskLabels.push(value['created_date']);
                    taskData['All'].push(value['total']);
                    taskData['Closed'].push(value['closed_tasks']);
                });

                let taskSeries = [{
                    name: 'All',
                    data: taskData['All'],
                    type: 'bar',
                    showBackground: true,
                },{
                    name: 'Closed',
                    data: taskData['Closed'],
                    type: 'bar',
                    showBackground: true,
                }];

                let taskOption = {
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        top: '5%',
                        left: 'center'
                    },
                    xAxis: {
                        type: 'category',
                        data: taskLabels
                    },
                    yAxis: {
                        type: 'value'
                    },
                    series: taskSeries
                };

                taskOption && taskChart.setOption(taskOption);
            }

        }).catch((error) => {
            this.setState({
                loading: false,
                message: error.message,
            });
            
            if (error.message === 'Request failed with status code 401') {
                this.props.updateLoginStatus(false);
            }
        });
    }

    redirectToArticleLink(link) {
        window.open(link, '_blank')
    }

    createMarkup(html) {
        return {__html: html};
    }

    render() {
        return (
            <div>
                {/* Total widgets */}
                <ul className="grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-2 xl:grid-cols-3 mt-3">
                    {/* Total tasks */}
                    {this.state.data.widget_information && this.state.data.widget_information['status-total_tasks'] == 'on' ?
                    <li className="relative col-span-1 flex shadow-sm rounded-md pb-6 cursor-pointer" onClick={() => this.redirectToModule('Tasks')}>
                        <div
                            className='bg-pink-600 flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md'
                        >
                            <FontAwesomeIcon size='2x' icon={faTasks} />
                        </div>
                        <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                            <div className="flex-1 px-4 py-2 text-sm truncate">
                                <a href="#" className="text-gray-900 font-medium hover:text-gray-600">
                                    {this.state.data.widget_information 
                                        && this.state.data.widget_information['updated_label-total_tasks'] 
                                        && this.state.data.widget_information['updated_label-total_tasks'].length > 0 ? 
                                        this.state.data.widget_information['updated_label-total_tasks'] : 
                                        this.state.data.widgets ? this.state.data.widgets['total_tasks'] : ''}   
                                </a>
                                <p className="text-gray-500">{this.state.data.total_tasks} task(s)</p>
                            </div>
                        </div>
                    </li> : ''}

                    {/* Total invoices */}
                    {this.state.data.widget_information && this.state.data.widget_information['status-total_invoices'] == 'on' ?
                    <li className="relative col-span-1 flex shadow-sm rounded-md pb-6 cursor-pointer" onClick={() => this.redirectToModule('AOS_Invoices')}>
                        <div
                            className='bg-green-600 flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md'
                        >
                            <FontAwesomeIcon size='2x' icon={faFileInvoice} />
                        </div>
                        <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                            <div className="flex-1 px-4 py-2 text-sm truncate">
                                <a href="#" className="text-gray-900 font-medium hover:text-gray-600">
                                    {this.state.data.widget_information 
                                        && this.state.data.widget_information['updated_label-total_invoices'] 
                                        && this.state.data.widget_information['updated_label-total_invoices'].length > 0 ? 
                                        this.state.data.widget_information['updated_label-total_invoices'] : 
                                        this.state.data.widgets ? this.state.data.widgets['total_invoices'] : ''}   
                                </a>
                                <p className="text-gray-500">{this.state.data.total_invoices} invoice(s)</p>
                            </div>
                        </div>
                    </li> : ''}
                </ul>

                {/* Total widget ends here */}
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {/* Show articles */}
                    {this.state.data.blogs ?
                        this.state.data.blogs.map((data, index) => {
                            return <div key={index} className=" bg-white shadow-md rounded-lg p-6" onClick={() => this.redirectToArticleLink(data['lien_externe'])}>
                                <div className="h-48">
                                    {data['image'] ?
                                        <img className="object-cover w-full h-full" src={`data:image/jpeg;base64,${data['image']}`} alt={data['name']} />
                                    : ''}
                                </div>
                                <h3 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate text-left pt-4 pb-1" dangerouslySetInnerHTML={this.createMarkup(data['name'])}></h3>        
                                <p>Publié le {data['date_publication']}</p>
                                <p className='overflow-ellipsis overflow-hidden py-4' dangerouslySetInnerHTML={this.createMarkup(data['description'])}></p>
                        </div>;
                        })
                    : ''}

                    <div className=" bg-white shadow-md rounded-lg p-4" style={this.state.data.widget_information && this.state.data.widget_information['status-open_ticket_by_priority'] == 'on' ? {} : {display: 'none'}}>
                        <h3 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate text-center">
                            {this.state.data.widget_information && this.state.data.widget_information['updated_label-open_ticket_by_priority'] && this.state.data.widget_information['updated_label-open_ticket_by_priority'].length > 0 ? this.state.data.widget_information['updated_label-open_ticket_by_priority'] : this.state.data.widgets ? this.state.data.widgets['open_ticket_by_priority'] : ''}    
                        </h3> 
                        <div id="open_tickets_by_priority" className="h-96"></div>
                    </div>
                    <div className=" bg-white shadow-md rounded-lg p-4" style={this.state.data.widget_information && this.state.data.widget_information['status-tickets_by_status'] == 'on' ? {} : {display: 'none'}}>
                        <h3 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate text-center">
                            {this.state.data.widget_information && this.state.data.widget_information['updated_label-tickets_by_status'] && this.state.data.widget_information['updated_label-tickets_by_status'].length > 0 ? this.state.data.widget_information['updated_label-tickets_by_status'] : this.state.data.widgets ? this.state.data.widgets['tickets_by_status'] : ''}    
                        </h3> 
                        <div id="tickets_by_status" className="h-96"></div>
                    </div>
                    <div style={this.state.data.widget_information && this.state.data.widget_information['status-latest_cases'] == 'on' ? {} : {display: 'none'}}>
                        <h3 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate pb-6">
                            {this.state.data.widget_information && this.state.data.widget_information['updated_label-latest_cases'] && this.state.data.widget_information['updated_label-latest_cases'].length > 0 ? this.state.data.widget_information['updated_label-latest_cases'] : this.state.data.widgets ? this.state.data.widgets['latest_cases'] : ''}
                        </h3>
                        <div className="bg-white shadow overflow-hidden rounded-md">
                            <ul className="divide-y divide-gray-200">
                                {this.state.data.latest_cases && this.state.data.latest_cases.map((case_info, index) => (
                                    <li key={index} className="px-6 py-4">
                                        <Link className="cursor-pointer font-medium text-skin-primary hover:text-skin-primary-darker" to={`${process.env.PUBLIC_URL}/Cases/detail/${case_info.id}`}>{case_info.name}</Link> 
                                        <small className="text-sm pl-2 text-gray-500 truncate">({case_info.status})</small> 
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {this.state.data.latest_cases && this.state.data.latest_cases.length === 0 ? <Alert hideAlert={this.hideAlert} message={no_data_message} type="info" hideClose={true} /> : ''}
                    </div>
                    <div className=" bg-white shadow-md rounded-lg p-4" style={this.state.data.widget_information && this.state.data.widget_information['status-tasks'] == 'on' ? {} : {display: 'none'}}>
                        <h3 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate text-center">
                            {this.state.data.widget_information && this.state.data.widget_information['updated_label-tasks'] && this.state.data.widget_information['updated_label-tasks'].length > 0 ? this.state.data.widget_information['updated_label-tasks'] : this.state.data.widgets ? this.state.data.widgets['tasks'] : ''}    
                        </h3> 
                        <div id="tasks" className="h-96"></div>
                    </div>
                    <div className="sm:col-span-1 md:col-span-2 bg-white shadow-md rounded-lg p-4" style={this.state.data.widget_information && this.state.data.widget_information['status-tasks_all_vs_closed'] == 'on' ? {} : {display: 'none'}}>
                        <h3 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate text-center">
                            {this.state.data.widget_information && this.state.data.widget_information['updated_label-tasks_all_vs_closed'] && this.state.data.widget_information['updated_label-tasks_all_vs_closed'].length > 0 ? this.state.data.widget_information['updated_label-tasks_all_vs_closed'] : this.state.data.widgets ? this.state.data.widgets['tasks_all_vs_closed'] : ''}    
                        </h3> 
                        <div id="tasks_all_vs_closed" className="h-96"></div>
                    </div>
                    <div style={this.state.data.widget_information && this.state.data.widget_information['status-latest_documents'] == 'on' ? {} : {display: 'none'}}>
                        <h3 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate pb-6">
                            {this.state.data.widget_information && this.state.data.widget_information['updated_label-latest_documents'] && this.state.data.widget_information['updated_label-latest_documents'].length > 0 ? this.state.data.widget_information['updated_label-latest_documents'] : this.state.data.widgets ? this.state.data.widgets['latest_documents'] : ''}
                        </h3>
                        <div className="bg-white shadow overflow-hidden rounded-md">
                            <ul className="divide-y divide-gray-200">
                                {this.state.data.latest_documents && this.state.data.latest_documents.map((document_info, index) => (
                                    <li key={index} className="px-6 py-4">
                                        <Link className="cursor-pointer font-medium text-skin-primary hover:text-skin-primary-darker" to={`${process.env.PUBLIC_URL}/Documents/detail/${document_info.id}`}>{document_info.document_name}</Link> 
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {this.state.data.latest_documents && this.state.data.latest_documents.length === 0 ? <Alert hideAlert={this.hideAlert} message={no_data_message} type="info" hideClose={true} /> : ''}
                    </div>
                    <div style={this.state.data.widget_information && this.state.data.widget_information['status-meetings'] == 'on' ? {} : {display: 'none'}}>
                        <h3 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate pb-6">
                            {this.state.data.widget_information && this.state.data.widget_information['updated_label-meetings'] && this.state.data.widget_information['updated_label-meetings'].length > 0 ? this.state.data.widget_information['updated_label-meetings'] : this.state.data.widgets ? this.state.data.widgets['meetings'] : ''}
                        </h3>
                        <div className="bg-white shadow overflow-hidden rounded-md">
                            <ul className="divide-y divide-gray-200">
                                {this.state.data.meetings && this.state.data.meetings.map((meeting, index) => (
                                    <li key={index} className="px-6 py-4">
                                        <Link className="cursor-pointer font-medium text-skin-primary hover:text-skin-primary-darker pr-2" to={`${process.env.PUBLIC_URL}/Meetings/detail/${meeting.id}`}>{meeting.name}</Link> 
                                        <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-skin-primary text-skin-muted">{meeting.status}</span>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        {this.state.data.meetings && this.state.data.meetings.length === 0 ? <Alert hideAlert={this.hideAlert} message={no_data_message} type="info" hideClose={true} /> : ''}
                    </div>
                </div>
            </div>
        );
    }
}

export default Dashboard;