import React, { Component } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import './Date.css';
import Cookies from 'js-cookie';
import "trix/dist/trix.css";
import { parseISO } from 'date-fns';
import Dropzone from 'react-dropzone';

class FormElement extends Component 
{
    constructor(props) {
        super(props);
        this.trixInput = React.createRef();
    }

    componentDidMount() {
        if(this.props.module == 'Cases' && this.props.field_name == 'description') {
            this.trixInput.current.addEventListener("trix-change", event => {
                this.props.handleChange(event);
            });   
        }
    }

    onDateTimeChange(date_value) {
        if(date_value) {
            date_value = date_value.getFullYear() + '-' + ('0' + (date_value.getMonth() + 1)).slice(-2) + '-' + ('0' + date_value.getDate()).slice(-2) + ' ' + ('0' + date_value.getHours()).slice(-2) + ':' + ('0' + date_value.getMinutes()).slice(-2) + ':00';
        }
        this.props.handleDateChange(this.props.field_name, date_value);
    }

    onDateChange(date_value) {
        if(date_value) {
            date_value = date_value.getFullYear() + '-' + ('0' + (date_value.getMonth() + 1)).slice(-2) + '-' + ('0' + date_value.getDate()).slice(-2);
        }
        this.props.handleDateChange(this.props.field_name, date_value);
    }

    render() {

        let previewImage = {
            border: '1px solid #ddd',
            borderRadius : '4px',
            padding: '5px',
            width: '150px',
            marginTop:'1rem'
        }

        let required = ''; 
        if(this.props.more_info.required == true || this.props.more_info.required == 'true') {
            required = 'data-pristine-required';
        }

        let date_format = this.props.date_format;
        date_format = date_format.replace('m', 'MM');
        date_format = date_format.replace('d', 'dd');

        let element;
        var fileMaxSizeBytes = parseInt(Cookies.get('max_attach_file_size'));
        var fileMaxSizeMegaBytes = fileMaxSizeBytes / 1000000;
        let sizeError = 'Votre fichier est trop volumineux. La taille maximale autorisée est '+fileMaxSizeMegaBytes+' Mo'; 
        let error_class = this.props.errors[this.props.field_name] == true ? 'is-invalid' : '';
        let error_message = this.props.errors[this.props.field_name] == 'File Size is Greater than limit' ? sizeError : '';
        
        switch(this.props.type) {   
            case "text":
                element = <input data-pristine-required={required} data-pristine-required-message="Attention, ce champ est obligatoire" type="text" className={`mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-skin-primary focus:border-skin-primary sm:text-sm ${error_class}`} id={this.props.field_name} name={this.props.field_name} value={this.props.field_value != 'null' ? this.props.field_value : ''} onChange={this.props.handleChange} />;
                break;
            case "checkbox":
                element = <input type="checkbox" data-pristine-required-message="Attention, ce champ est obligatoire" defaultChecked={this.props.field_value == '1' ? true : false} className={`focus:ring-skin-primary h-4 w-4 text-skin-primary-dark border-gray-300 rounded ${error_class}`} id={this.props.field_name} name={this.props.field_name} value={this.props.field_value} onChange={this.props.handleChange} />
                break;
            case "select":
                let select_options = '';
                
                if(this.props.bubble){
                    let bubbleSelectio = '';
                    if(Object.keys(this.props.more_info.options).length) { 
                       
                        bubbleSelectio =             
                            <div className="bg-gray-100 px-4 py-5 sm:px-6" >
                                <div className="grid grid-cols-4 gap-4">
                                    {Object.keys(this.props.more_info.options).map((option_key, index) => {
                                        var match = (this.props.field_value == option_key ) ? true : false;
                                        if(this.props.more_info.options[option_key]){
                                            return (
                                                <button value={option_key} type="button" onClick={this.props.handleChange} name={this.props.field_name} className={`${(match) ? 'bg-gray-500 text-white' : 'bg-white'} px-2 py-2 rounded-xl text-center border-gray-100`}>
                                                    {this.props.more_info.options[option_key]}
                                                </button>
                                            )    
                                        }                                    
                                    })}
                                </div>
                                {this.props.custom_error && this.props.custom_error.hasOwnProperty(this.props.field_name) && this.props.custom_error[this.props.field_name] === false
                                ? <span className='text-red-500'> Attention, ce champ est obligatoire</span>
                                : ""
                                }
                            </div>               
                    };
                    element = bubbleSelectio;
                } else {
                    if(Object.keys(this.props.more_info.options).length) {
                        select_options = Object.keys(this.props.more_info.options).map((option_key, index) => {
                            if(index == 0) {
                                return (
                                    <>
                                    <option value='' key="none">Select</option>
                                    <option value={option_key} key={option_key}>{this.props.more_info.options[option_key]}</option>
                                    </>
                                )
                            }
                            return (
                                <option value={option_key} key={option_key}>{this.props.more_info.options[option_key]}</option>
                            );
                        });
                    };
                    element = <select data-pristine-required={required} data-pristine-required-message="Attention, ce champ est obligatoire" name={this.props.field_name} id={this.props.field_name} value={this.props.field_value} className={`mt-1 border rounded-md w-full border-gray-300 shadow-sm placeholder-gray-400 focus:outline-none focus:ring-skin-primary focus:border-skin-primary sm:text-sm ${error_class}`} onChange={this.props.handleChange}> {select_options} </select>;
                }
                break;    
            case "textarea":
                element = this.props.module == 'Cases' && this.props.field_name == 'description' ? 
                <>
                    <div className='pt-2'> <input id={this.props.field_name} type="hidden" onChange={this.props.handleChange} name={this.props.field_name} value={this.props.field_value != 'null' ? this.props.field_value : ''} /> <trix-editor placeholder="Attention: les demandes transmises dans le portail ne doivent pas contenir de données de santé ou a caractère personnel." input={this.props.field_name} ref={this.trixInput}></trix-editor> </div>
                    {this.props.custom_error && this.props.custom_error.hasOwnProperty(this.props.field_name) && this.props.custom_error[this.props.field_name] === false
                        ? <span className='text-red-500'> Attention, ce champ est obligatoire</span>
                        : ""
                    }
                </>
                :
                <textarea data-pristine-required={required} data-pristine-required-message="Attention, ce champ est obligatoire" rows="3" type="text" className={`mt-1 max-w-lg shadow-sm block w-full focus:ring-skin-primary focus:border-skin-primary sm:text-sm border border-gray-300 rounded-md ${error_class}`} id={this.props.field_name} name={this.props.field_name} value={this.props.field_value != 'null' ? this.props.field_value : ''} onChange={this.props.handleChange} />
                break;    
            case "image":
                let input_element = <input type="file" className='form-control-file' id={this.props.field_name}  name={this.props.field_name} onChange={this.props.handleChange} accept="image/*" />;
                let preview_image = '';//this.props.field_value ? <div>{this.props.field_value}</div> : '';
                if(error_class) { 
                    element = [input_element, <small className="text-red-600 form-text">{this.props.field_label} field is mandatory</small>];
                }
                if(error_message) { 
                    element = [input_element, <div><small className="text-red-600 form-text">{this.props.field_label} {error_message}</small></div>];
                }
                else {
                    element = [input_element, preview_image];
                }
                break;    
            case "file":
                input_element = <input type="file" ref={this.props.fileInput} className='form-control-file' id={this.props.field_name} name={this.props.field_name} onChange={this.props.handleChange} />;
                if(error_class) {
                    element = [input_element, <small className="text-red-600 form-text">{this.props.field_label} field is mandatory</small>];
                }
                if(error_message) { 
                    element = [input_element, <div><small className="text-red-600 form-text">{this.props.field_label} {error_message}</small></div> ];
                }
                else {
                    element = [input_element, <p></p>];
                }
                break;
            case "datetime":
                let date_time_value = '';
                if(this.props.field_value) {
                    date_time_value = new Date(parseISO(this.props.field_value));
                }

                element = <DatePicker data-pristine-required={required} showTimeSelect selected={date_time_value} dateFormat={`${date_format} hh:mm:ss`} timeFormat="HH:mm" timeIntervals={15} timeCaption="time" className={`mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-skin-primary focus:border-skin-primary sm:text-sm ${error_class}`} id={this.props.field_name} name={this.props.field_name} onChange={this.onDateTimeChange.bind(this)} />;
                break;
            case "date":
                let date_value = '';
                if(this.props.field_value) {
                    date_value = new Date(parseISO(this.props.field_value));
                }
                
                // yyyy-MM-dd
                element = <DatePicker data-pristine-required={required} selected={date_value} dateFormat={date_format} className={`mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-skin-primary focus:border-skin-primary sm:text-sm ${error_class}`} id={this.props.field_name} name={this.props.field_name} onChange={this.onDateChange.bind(this)} />;
                break;
                case "drag_n_drop":
                    element = 
                    <div className='dropzone-container'>
                        <div className='dropzone-area'>
                            <Dropzone onDrop={(files) => this.props.handleDrapNDrop(files, this.props.field_name)}>
                                {({getRootProps, getInputProps}) => (
                                    <section className="container">
                                         <div {...getRootProps()}>
                                            <input className='dropzone-input' {...getInputProps()} />
                                                <p className='dropzone-textarea'>Drag and Drop a file</p>
                                                <div className='dropzone-fileuploader'>
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-9 w-9" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-8l-4-4m0 0L8 8m4-4v12" />
                                                    </svg>
                                                </div>
                                        </div>
                                        
                                    </section>
                                )}
                            </Dropzone>
                        </div>
                        {this.props.field_value && this.props.field_value.length !== 0?  
                           <div className='dropzone-preview'>
                              {(this.props.field_value).map( (file, index) => {
                                return(
                                    <div className='flex bg-white p-3 gap-2 m-1 justify-between'> 
                                        
                                        <div className='flex'>
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                                            </svg>
                                            <span className='ml-2'> {file.file_name} </span>
                                        </div>
                                        <div id='file-remover' className='text-danger px-2 cursor-pointer text-red-500' onClick={() => this.props.removeFileHandler(index, this.props.field_name)}> 
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                            </svg>
                                        </div>
                                    </div>
                                )
                              })}
                           </div>
                        : ''}
                    </div>
                    break;
                    
                    
            default:
                element = <input data-pristine-required={required} data-pristine-required-message="Attention, ce champ est obligatoire" type="text" className={`mt-1 appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-skin-primary focus:border-skin-primary sm:text-sm ${error_class}`} id={this.props.field_name} name={this.props.field_name} value={this.props.field_value} onChange={this.props.handleChange} />;
                break;
        }
        return element;
    }
}

export default FormElement;