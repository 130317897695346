import { Link } from "react-router-dom";
import React from 'react';
import classNames from '../../../../Helper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAddressBook } from '@fortawesome/free-solid-svg-icons';

function Nav(props)
{
    return (
        <nav className="px-3 mt-6">
            <div className="space-y-1">
                {props.navigation.map((item) => {
                    return (<Link
                        key={item.name}
                        to={item.href}
                        onClick={() => props.updateCurrentNav(item.name)}
                        className={classNames(
                            item.current ? 'bg-regalblue-200 text-white' : 'text-violet-900 hover:text-white hover:bg-regalblue-50',
                            'group flex items-center px-2 py-2 text-sm font-medium rounded-md'

                        )}
                        aria-current={item.current ? 'page' : undefined}
                    >
                        {item.name == 'Annuaire' ?
                            <FontAwesomeIcon 
                                icon={faAddressBook} size="2x" color={item.current ? '#fff' : '#415987'} 
                                className={classNames(
                                    item.current ? 'text-white' : 'group-hover:text-white',
                                    'mr-3 flex-shrink-0 h-6 w-6'
                                )}    
                            />
                        : 
                        <item.icon
                            style={item.current ? {color: '#fff'} : {color: '#415987'}}
                            className={classNames(
                                item.current ? 'text-white' : 'text-gray-400 group-hover:text-white',
                                'mr-3 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                        />}
                        {item.name}
                    </Link>);
                })}
            </div>
      </nav>
    )
}

export default Nav;