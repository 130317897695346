import React, { Component } from 'react';
import Axios from 'axios';
import PortalConfig from '../../../config';
import Feather from 'feather-icons';
import Cookies from 'js-cookie';
import { Link } from "react-router-dom";
import FormElement from '../FormElement';
import { ChevronRightIcon, CodeIcon, ColorSwatchIcon } from '@heroicons/react/outline';
import Alert from '../../Alerts/Alert';
import Pristine from "pristinejs";

const defaultConfig = {
    // class of the parent element where the error/success class is added
    classTo: 'form-group',
    errorClass: 'has-danger',
    successClass: 'has-success',
    // class of the parent element where error text element is appended
    errorTextParent: 'form-group',
    // type of element to create for the error text
    errorTextTag: 'div',
    // class of the error text element
    errorTextClass: 'text-red-500 text-xs'
};

class EditView extends Component {
    state = {
        name: '',
        errors: {},
        fields: [],
        files: [],
        record_info: [],
        message: '',
        loading: false,
        layout_column: 1,
        parent_id: '',
        labels: [],
        date_format: JSON.parse(Cookies.get('ba_customer_portal_information'))['date_format'],
        field_perimission: '',
        originalChildOptions: {},
        custom_error: {}
    }

    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.handleDateChange = this.handleDateChange.bind(this);
        this.fileInput = React.createRef();
        this.hideErrorMessage = this.hideErrorMessage.bind(this);
        
        // Drag and Drop Files
        this.handleDrapNDrop = this.handleDrapNDrop.bind(this);
        this.removeFileHandler = this.removeFileHandler.bind(this);
    }

    // Set loading and message to null
    hideErrorMessage(event) {
        this.setState({
            loading: false,
            message: ''
        });
    }

    // Handle input change
    handleChange(event) {
        var error = [];
        var error_flag = false;
        const name = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        let newState = Object.assign({}, this.state);
        if (event.target.type == 'file' && event.target.files) {
            var size = event.target.files[0].size;
            var limit = parseInt(Cookies.get('max_attach_file_size'));
            if(limit > size ){
                newState['files'][name] = event.target.files[0];
                newState['fields'][name] = event.target.files[0]['name'];
                error[name] = '';
            } else {
                error[name] = 'File Size is Greater than limit';
                error_flag = true;                
            }
        }
        else {
            newState['fields'][name] = value;
        }
        this.setState({errors: error});
        if(!error_flag){
            this.setState({ newState });
        } else {
            newState['fields'][name] = '';
        }
        
    }
    // Handle buble change  

    // Handle date change
    handleDateChange(field_name, field_value) {
        let newState = Object.assign({}, this.state);
        newState['fields'][field_name] = field_value;
        this.setState({ newState });
    }

    /**
     * Validate Submit the form
     */
    handleSubmit() {
        // Validate the data
        let is_validated = false;
        var pristine = new Pristine(document.getElementById(`${this.props.selected_module}-editView`), defaultConfig);
        is_validated = pristine.validate(document.querySelectorAll('input[data-pristine-required="data-pristine-required"], select[data-pristine-required="data-pristine-required"], textarea[data-pristine-required="data-pristine-required"]'));
        if (!is_validated) {
            return false;
        }
        let newState = Object.assign({}, this.state);
        let is_check = true;
        Object.entries(this.state.record_info).map(([index, record_info]) => {
           let field_info = record_info.fields; 
           if(field_info && Object.keys(field_info).length != 0) {
              (field_info).map( (fields) => {
                let is_mandatory = fields.more_info.required;
                if(is_mandatory || is_mandatory == 'true') {
                   Object.entries(this.state.fields).map( ([name, value]) => {
                      if(fields.field_name == name && (value == 'null' || !value) ) {
                        is_check = false;
                        newState['custom_error'][fields.field_name] = false;
                      } 
                   });
                   if(this.state.custom_error && Object.keys(this.state.custom_error) != 0) {
                    Object.entries(this.state.custom_error).map(([error_field, error_status]) => {
                        if(!error_status && this.state.fields.hasOwnProperty(error_field) && this.state.fields[error_field]) {
                            newState['custom_error'][fields.field_name] = true;
                        }
                    });
                   }
                   this.setState({ newState });
                }
              });
           }
        });

        if(!is_check) return false;

        let post_data = new FormData();

        if (this.state.parent_id) {
            if (this.props.selected_module == 'ProjectTask') {
                post_data.append('project_id', this.state.parent_id);
            }
        }

        if (!this.state.fields) {
            this.setState({
                message: 'No fields present in the layout. Please contact admin.',
                loading: false,
            });
            return false;
        }

        Object.keys(this.state.fields).map((field_name) => {
            post_data.append(field_name, this.state.fields[field_name]);
        });

        // Set data for image type
         this.state.record_info.map((layout_info) => {
            if(layout_info.fields) {
                return layout_info.fields.map((field_info) => {
                    if(field_info.type == 'image' || (field_info.type == 'file' || this.props.selected_module != 'Documents')) {
                        if(this.state.files[field_info.field_name]) {
                            post_data.set(field_info.field_name, this.state.files[field_info.field_name]);
                        }
                    }
                    if(field_info.type == 'drag_n_drop') {
                        if(this.state.files[field_info.field_name]) {
                            let files = this.state.files[field_info.field_name];
                            if(files.length != 0) {
                                for(let x = 0; x < files.length ; x++) {
                                    post_data.append(field_info.field_name+"[]", files[x]);
                                }
                            }
                            post_data.set(field_info.field_name, this.state.files[field_info.field_name]);
                        }
                        if(this.state.fields['ba_exiting_file']) {
                            post_data.set('ba_exiting_file', this.state.fields['ba_exiting_file']);
                        }
                    }
                });
            }
        });

        if (this.props.selected_module === 'Documents' && this.fileInput.current && this.fileInput.current.files[0] && this.props.record_id == 'new') {
            // Set the file information
            var size = this.fileInput.current.files.size;
            var limit = parseInt(Cookies.get('max_attach_file_size'));
            if (limit > size) {
                post_data.set('filename', this.fileInput.current.files[0]);
            }
        }

        this.setState({ loading: true });

        let method;
        let endpoint_url;
        if (this.props.record_id === 'new') {
            method = 'post';
            endpoint_url = PortalConfig.crm_url + '/' + this.props.selected_module;
        }
        else {
            method = 'post';
            endpoint_url = PortalConfig.crm_url + '/' + this.props.selected_module + '/' + this.props.record_id;
        }

        const token = Cookies.get('ba_customer_portal_token');

        const config = {
            url: endpoint_url,
            method: method,
            data: post_data,
            headers: {
                Authorization: 'bearer ' + token,
            }
        };

        Axios(config).then((response) => {
            if (response.data.status === true) {
                // Show message and redirect to Detail view of the record
                this.setState({
                    message: response.data.message,
                    loading: false,
                });

                this.props.history.push(process.env.PUBLIC_URL + '/' + this.props.selected_module + '/detail/' + response.data.record_id);

            }
            else {
                this.setState({
                    message: 'Something went wrong!',
                    loading: false,
                });
            }
        })
            .catch((error) => {
                let error_message;
                if (error.response) {
                    error_message = error.response.data.message;
                    if (error_message == undefined) {
                        error_message = error.response.statusText;
                    }
                }
                else {
                    error_message = error.message
                }

                // Set error status and error message
                this.setState({
                    message: error_message,
                    loading: false,
                });

                if (error.message == 'Request failed with status code 401') {
                    this.props.updateLoginStatus(false);
                }
            });
    }
    
    handleDrapNDrop(files, field_name) {
        let newState = Object.assign({}, this.state);
        let newFile = this.state.files;

        // GET the file details
        if(files) {
            for(var i = 0; i < files.length; i++) {
                let fileData = {};
                fileData['file_name'] = files[i]['name'];
                fileData['file_size'] = files[i]['size'];
                newState['fields'][field_name].push(fileData);
            }
        }

        // ADD the file 
        if(!newFile[field_name] || newFile[field_name].length === 0) {
            newState['files'][field_name] = files;
        } else {
            if(files.length > 0) {
                for(let i = 0; i < files.length; i++) {
                    newState['files'][field_name].push(files[i]);
                }
            }
        }
        this.setState({newState}); 
    }

    removeFileHandler(index, field_name) {

        let newState = Object.assign({}, this.state); 

        // Remove the file from edit view 
        if(this.props.record_id != 'new') {
            let fields = this.state.fields[field_name];
            if(!this.state.fields['ba_exiting_file']) {
                newState['fields']['ba_exiting_file'] = fields[index]['id']; // Remove file ID
            } else {
                let deletedFileId = this.state.fields['ba_exiting_file'];
                deletedFileId +=  ',' + fields[index]['id'];
                newState['fields']['ba_exiting_file'] = deletedFileId;
            }

            fields.splice(index,1);
            newState['fields'][field_name] = fields;
            this.setState({newState});
            return false;
        }

        // Check if the files is exits or not
        if(!this.state.files[field_name] || !this.state.fields[field_name]) return false; 

        // Ready to remove the file
        let files = this.state.files[field_name];
        let fields = this.state.fields[field_name];
        files.splice(index, 1);
        fields.splice(index,1);
        newState['files'][field_name] = files;
        newState['fields'][field_name] = fields;
        this.setState({newState});
    }

    componentDidMount() {
        this.getRecordInfo();
        const queryString = require('query-string');
        let parsed = queryString.parse(window.location.search);

        if (parsed.parent_id) {
            this.setState({
                parent_id: parsed.parent_id
            });
        }
    }

    changeChildOptions(field_info) {
        var parentFieldValue = this.state.fields[field_info.parent];  
        if(this.state.originalChildOptions[field_info.field_name]){
            var childDropDownOptions = (this.state.originalChildOptions[field_info.field_name]) ? this.state.originalChildOptions[field_info.field_name] : {};
        } else {
            var childDropDownOptions = (field_info.more_info.options)? field_info.more_info.options : {};
            this.state.originalChildOptions[field_info.field_name] = (childDropDownOptions) ? childDropDownOptions : {};
        }
        var childFilterOptions = {};
        if (parentFieldValue) {

            Object.keys(childDropDownOptions).map((name) => {
                if (name.startsWith(parentFieldValue)) { 
                   childFilterOptions[name] = childDropDownOptions[name];
                  }
            });
        }        
        return childFilterOptions;
    }

    getRecordInfo() {
        // Show loading icon
        this.setState({
            loading: true,
        });

        const token = Cookies.get('ba_customer_portal_token');
        const config = {
            responseType: 'json',
            headers: {
                Authorization: 'bearer ' + token,
            }
        };

        Axios.get(PortalConfig.crm_url + '/' + this.props.selected_module + '/' + this.props.record_id + '/editview', config).then((response) => {
            let response_data = response.data;
            if (response_data.status === false) {
                this.setState({
                    message: response_data.message,
                    loading: false,
                });
            }
            else {
                this.setState({
                    name: response_data.name,
                    fields: response_data.fields,
                    record_info: response_data.record_info,
                    layout_column: response_data.layout_column,
                    loading: false,
                    labels: response.data.labels,
                    field_perimission: response.data.field_permissions,
                });
                Feather.replace();
            }
        }).catch((error) => {
            this.setState({
                loading: false,
                message: error.message,
            });

            if (error.message === 'Request failed with status code 401') {
                this.props.updateLoginStatus(false);
            }
        });
    }

    render() {

        return (
            <div>
                <nav className="flex justify-between" aria-label="Breadcrumb">
                    <ol className="flex items-center space-x-2 bg-white rounded-lg p-4">
                        <li>
                            <div>
                                <Link style={{ color: "#1F3B71" }} className="text-skin-primary hover:text-skin-primary-darker" to={`${process.env.PUBLIC_URL}/${this.props.selected_module}`}>{this.props.moduleLabel}</Link>
                            </div>
                        </li>
                        <li>
                            <div className="flex items-center">
                                <ChevronRightIcon className="flex-shrink-0 h-4 w-4 text-gray-400" aria-hidden="true" />
                                <p className='pl-2'>{this.state.name}</p>
                            </div>
                        </li>
                        {this.state.loading == true ?
                            <svg className="text-skin-primary-darker animate-spin ml-3 h-5 w-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            : ''}
                    </ol>
                    <ol className="flex space-x-2">
                        <li className='mt-2'>
                            <Link className="font-medium text-skin-base hover:text-skin-primary-darker" to={`${process.env.PUBLIC_URL}/${this.props.selected_module}`}>
                                {this.props.module_action_label && this.props.module_action_label.cancel ? this.props.module_action_label.cancel : 'Annuler'}
                            </Link>
                        </li>
                        <li>
                            <button type="button" onClick={() => this.handleSubmit()} className="order-0 inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-skin-muted bg-skin-primary-dark hover:bg-skin-primary-darker focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-skin-primary sm:order-1 sm:ml-3">{this.props.module_action_label && this.props.module_action_label.save ? this.props.module_action_label.save : 'Enregistrer'}</button>
                        </li>
                    </ol>
                </nav>

                <form id={`${this.props.selected_module}-editView`}>

                    {this.state.message ? <Alert message={this.state.message} type='danger' hideAlert={this.hideErrorMessage} /> : ''}

                    {this.state.record_info.map((layout_info, layout_index) => {

                        let field_chunks = [];
                        let layout_fields = [];
                        layout_fields = Object.assign([], layout_info.fields);

                        while (layout_fields.length) {
                            field_chunks.push(layout_fields.splice(0, this.state.layout_column));
                        }

                        let column_class = 'md:grid-cols-1';
                        if (this.state.layout_column == 2) {
                            column_class = 'md:grid-cols-2';
                        }
                        else if (this.state.layout_column == 3) {
                            column_class = 'md:grid-cols-3';
                        }

                        return (
                            <div key={layout_index}>
                                <div className="pt-7 pb-1">
                                    <h3 className="text-lg leading-6 font-medium text-gray-900">{layout_info.header_name}</h3>
                                </div>
                                <div className="border-t border-gray-200 py-3">
                                    <div className={`grid ${column_class} gap-x-4 gap-y-8 sm:grid-cols-2`}>
                                        {field_chunks.map((fields, index) => {
                                            return (
                                                <>
                                                    {fields.map((field_info, index) => {

                                                        let field_value = this.state.fields[field_info.field_name];
                                                        let mandatory_span;
                                                        if (field_info.more_info.required) {
                                                            mandatory_span = <span className="text-red-600">*</span>;
                                                        }

                                                        // For Dynamic Dropdown
                                                        if(field_info.parent != '') {                                                           
                                                            var options = this.changeChildOptions(field_info );
                                                            field_info.more_info.options = options;
                                                        }

                                                        return (
                                                            <>
                                                                {(this.state.field_perimission.hide == undefined && Object.keys(this.state.field_perimission.hide).length == 0 ) || (this.state.field_perimission.hide && (this.state.field_perimission.hide).indexOf(field_info.field_name) < 0)  &&
                                                                    <div className="form-group sm:col-span-1" key={index}>
                                                                        <label htmlFor={field_info['field_name']} className="block text-sm font-medium text-gray-700">
                                                                            {/* {this.state.field_perimission.read_only && (this.state.field_perimission.read_only).indexOf(field_info.field_name) > -1 ?
                                                                                    <></>
                                                                                :
                                                                                    <>{field_info['label']} {mandatory_span}</>
                                                                            } */}
                                                                            {this.state.field_perimission.label && (this.state.field_perimission.label).indexOf(field_info.field_name) > -1 ?
                                                                            <>
                                                                            {Object.keys(field_info.more_info.options).length > 0 &&
                                                                                <div className='rounded-md bg-red-50 p-4 pb-0'>
                                                                                <b className='text-red-600 flex'> 
                                                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                                                                                        <path fill-rule="evenodd" d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z" clip-rule="evenodd" />
                                                                                    </svg>
                                                                                        {/* Avant d'aller plus loin, vous pourrez peut-être trouver la {field_info['label']} dans nos boîtes à outils: */}
                                                                                        Informations disponibles :
                                                                                </b>
                                                                                </div>
                                                                            }
                                                                            </>
                                                                            
                                                                        :
                                                                            <>  {field_info['label']} {mandatory_span} </>
                                                                        }
                                                                        </label>
                                                                        {field_info['field_name'] === 'filename' && this.props.record_id != 'new' && this.props.selected_module === 'Documents' ?
                                                                            <div className="font-bold">{field_value} </div> :
                                                                            <>
                                                                                {this.state.field_perimission.read_only && (this.state.field_perimission.read_only).indexOf(field_info.field_name) > -1 ?
                                                                                    <label htmlFor={field_info['field_name']} className="block text-base font-medium text-gray-900 ">
                                                                                        {field_info.type == 'select' ?
                                                                                            <>
                                                                                                {this.state.field_perimission.label && Object.keys(field_info.more_info.options).length > 0 && (this.state.field_perimission.label).indexOf(field_info.field_name) > -1  ?
                                                                                                    <div className='rounded-md bg-red-50 p-4 pt-0'>
                                                                                                        {Object.keys(field_info.more_info.options).map(function (option_value) {
                                                                                                            var [label, url] = field_info.more_info.options[option_value].split(';');
                                                                                                            label = label.replaceAll('"', '');
                                                                                                            url = url.replaceAll('"', '');
                                                                                                            return (
                                                                                                                <div className='text-red-600 ml-4'>
                                                                                                                    <p> - <a href={url}>{label} </a> </p>
                                                                                                                </div>
                                                                                                            )
                                                                                                        })}
                                                                                                    </div>
                                                                                                :
                                                                                                    <div className='ml-4'>  {(field_info.more_info.options[field_value])&& <>{field_info.more_info.options[field_value]} </> }   </div>
                                                                                                }
                                                                                             </>
                                                                                            :
                                                                                            <>{field_value}</>
                                                                                        }
                                                                                        
                                                                                    </label>
                                                                                    :
                                                                                    <FormElement 
                                                                                        module={this.props.selected_module}
                                                                                        handleChange={this.handleChange} 
                                                                                        handleDateChange={this.handleDateChange}
                                                                                        handleDrapNDrop={this.handleDrapNDrop}
                                                                                        removeFileHandler={this.removeFileHandler}
                                                                                        more_info={field_info.more_info}
                                                                                        field_name={field_info.field_name}
                                                                                        field_value={field_value}
                                                                                        type={field_info.type}
                                                                                        label={field_info.label}
                                                                                        errors={this.state.errors}
                                                                                        fileInput={this.fileInput}
                                                                                        files={this.state.files}
                                                                                        date_format={this.state.date_format}
                                                                                        bubble={this.state.field_perimission.bubble && (this.state.field_perimission.bubble).indexOf(field_info.field_name) > -1 ? true : false }
                                                                                        parent={field_info.parent}
                                                                                        custom_error={this.state.custom_error}
                                                                                    />
                                                                                }
                                                                            </>
                                                                        }
                                                                    </div>
                                                                }
                                                            </>
                                                        );
                                                    })}
                                                </>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        );
                    })}

                </form>
            </div>
        );
    }
}

export default EditView;
