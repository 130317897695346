const custom_config = {
    //crm_url: 'https://demo.blackant.io/suitecrm/AndoDND',
    crm_url: 'https://portailgrc.cdg74.fr',

};

const portal_config = {
    crm_url: custom_config.crm_url + '/api',
    portal_url: 'https://portailgrc.cdg74.fr',
    layout: 'main',
};

export default portal_config;
